import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { AppStateContext } from "../../state/AppProvider";
// import { DismissRegular } from "@fluentui/react-icons";
import styles from "./ManageConversation.module.css";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
} from "@fluentui/react-components";
import {
  getArtifactNamesApi
} from "../../api";

const ManageConversation = () => {
  const [openItems, setOpenItems] = React.useState(["1", "2", "3"]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };
  const appStateContext = useContext(AppStateContext);
  const abortController = new AbortController();

  // Define the data fetching function
  const fetchData = async () => {
    try {
      const response = await getArtifactNamesApi(abortController.signal);
      const data = await response.json();

      // Extract evidence and assessment blob names
      const evidenceBlobNames = data.evidence_blob_names || [];
      const assessmentBlobNames = data.assessment_blob_names || [];

      appStateContext?.dispatch({
        type: "UPDATE_EVIDENCES",
        payload: evidenceBlobNames,
      });
      appStateContext?.dispatch({
        type: "UPDATE_ASSESSMENT_FILES",
        payload: assessmentBlobNames,
      });
    } catch (error) {
      console.error("Error fetching artifact names:", error);
    }
  };

  // Use useEffect to call fetchData when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const evidenceLists = appStateContext?.state.evidences;
  const assessmentFileLists = appStateContext?.state.assessmentFiles;
  //   const selectedEvidences = appStateContext?.state.selectedEvidences;
  const handleCheckboxChange = (
    title: string,
    isChecked: boolean,
    listType: string
  ) => {
    if (listType === "evidence" && appStateContext) {
      const updatedEvidences = isChecked
        ? [...appStateContext.state.selectedEvidences, title]
        : appStateContext.state.selectedEvidences.filter((item) => item !== title);
      appStateContext.dispatch({
        type: "UPDATE_SELECTED_EVIDENCES",
        payload: updatedEvidences,
      });
    } else if (listType === "assessment" && appStateContext) {
      const updatedAssessmentFiles = isChecked
        ? [...appStateContext.state.selectedAssessmentFiles, title]
        : appStateContext.state.selectedAssessmentFiles.filter(
            (item) => item !== title
          );
      appStateContext.dispatch({
        type: "UPDATE_SELECTED_ASSESSMENT_FILES",
        payload: updatedAssessmentFiles,
      });
    }
    console.log(appStateContext?.state.selectedAssessmentFiles, "Assess");
    console.log(appStateContext?.state.selectedEvidences, "Evidence");
  };
  // Check if any item is selected from the store
  //   const isAnyItemSelected = !appStateContext
  //     ? false
  //       :appStateContext.state.selectedEvidences.length > 0 ||
  //       appStateContext.state.selectedAssessmentFiles.length > 0;
  return (
    <Stack.Item className={`${styles.historyPanel} ${styles.mobileStyles}`} style={{display:appStateContext?.state.isManagePanel? "flex": "none"}}>
      <Stack
        horizontal
        className={styles.historyPanelHeaderContainer}
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <span className={styles.historyPanelHeader}>My Documents</span>
      </Stack>
      <Stack>
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          <AccordionItem value="1">
            <AccordionHeader className={styles.accordionHeader}>
              Evidence Artifacts
            </AccordionHeader>
            {evidenceLists?.map((list) => (
              <AccordionPanel>
                <div className={styles.accordionItem}>
                  <input
                    type="checkbox"
                    checked={appStateContext?.state.selectedEvidences.includes(
                      list.title
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(
                        list.title,
                        e.target.checked,
                        "evidence"
                      )
                    }
                  ></input>
                  <div>{list.title}</div>
                </div>
              </AccordionPanel>
            ))}
          </AccordionItem>
          <AccordionItem value="2">
            <AccordionHeader className={styles.accordionHeader}>
              Assessment Files
            </AccordionHeader>
            {assessmentFileLists?.map((list) => (
              <AccordionPanel>
                <div className={styles.accordionItem}>
                  <input
                    type="checkbox"
                    checked={appStateContext?.state.selectedAssessmentFiles.includes(
                      list.title
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(
                        list.title,
                        e.target.checked,
                        "assessment"
                      )
                    }
                  ></input>
                  <div>{list.title}</div>
                </div>
              </AccordionPanel>
            ))}
          </AccordionItem>
        </Accordion>
      </Stack>
      {/* {isAnyItemSelected && (
        <div className={styles.btnContainer}>
          <button className={styles.button}>Delete</button>
        </div>
      )} */}
    </Stack.Item>
  );
};

export default ManageConversation;
