// import { Code16Filled } from "@fluentui/react-icons";
import { chatHistorySampleData } from '../constants/chatHistory';
import {
    ConversationRequest,
    ChatMessage, Conversation, CosmosDBHealth, CosmosDBStatus, UserInfo,
    LtiLogin, DeleteNamesRequest
} from "./models";

export async function callConversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    let response;

    const formData = new FormData();
    formData.append('conversation_id', options.id);
    formData.append('messages', JSON.stringify(options.messages));
    formData.append('attachments_exists', options.attachments_exists.toString());

    (options.files ?? []).forEach(file => {
        formData.append('files', file);
    });
    (options.filenames ?? []).forEach(filename => {
        formData.append('filenames', filename);
    });

    response = await fetch("/api/conversation", {
        method: "POST",
        body: formData,
        signal: abortSignal,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData.error));
    }

    return response;
}

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/conversation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            messages: options.messages
        }),
        signal: abortSignal
    })

    return response
}

export async function getArtifactNamesApi(abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/api/get_user_artifact_names', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        signal: abortSignal
    })

    return response
}

export async function deleteArtifactNamesApi(deleteNames: DeleteNamesRequest, abortSignal: AbortSignal): Promise<Response> {
    console.log(deleteNames)
    const response = await fetch('/api/delete_user_artifacts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            titles: deleteNames
        }),
        signal: abortSignal
    })

    return response
}

export async function getConversationHistoryApi(abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/api/get_user_conversation_history', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        signal: abortSignal
    })

    return response
}

export async function postLtiLogin(options: LtiLogin, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/lti/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            messages: options.target_link_uri
        }),
        signal: abortSignal
    })
    if (!response.ok) {
        throw new Error('HTTP error! status ${response.status}');
    }
    return response
}

export async function getLtiLogin(): Promise<LtiLogin> {
    const response = await fetch('/lti/login', {
        method: 'GET'
    })
    const payload = await response.json()
    return payload
}

export async function postLtiLaunch(options: LtiLogin): Promise<void> {
    try {
        const response = await fetch('/lti/launch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                messages: options.target_link_uri
            }),
        })
        if (!response.ok) {
            throw new Error('LTI Launch Error! status ${response.status}');
        }
        const html = await response.text();
        document.open();
        document.write(html);
        document.close();
    }
    catch (error) {
        console.error('Error posting LTI launch.', error);
        throw error;
    }
}

export async function getLtiJwks(): Promise<Response> {
    const response = await fetch('/jwks', {
        method: 'GET'
    })
    return response
}

export async function getUser(): Promise<Response> {
    const response = await fetch('/api/user', {
        method: 'GET'
    })
    return response
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me')
    if (!response.ok) {
        console.log('No identity provider found. Access to chat will be blocked.')
        // return []
    }

    const payload = await response.json()
    return payload
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    return chatHistorySampleData
}

export const historyList = async (offset = 0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: 'GET'
    })
        .then(async res => {
            const payload = await res.json()
            if (!Array.isArray(payload)) {
                console.error('There was an issue fetching your data.')
                return null
            }
            const conversations: Conversation[] = await Promise.all(
                payload.map(async (conv: any) => {
                    let convMessages: ChatMessage[] = []
                    convMessages = await historyRead(conv.id)
                        .then(res => {
                            return res
                        })
                        .catch(err => {
                            console.error('error fetching messages: ', err)
                            return []
                        })
                    const conversation: Conversation = {
                        id: conv.id,
                        title: conv.title,
                        date: conv.createdAt,
                        messages: convMessages
                    }
                    return conversation
                })
            )
            return conversations
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            return null
        })

    return response
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch('/history/read', {
        method: 'POST',
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(async res => {
            if (!res) {
                return []
            }
            const payload = await res.json()
            const messages: ChatMessage[] = []
            if (payload?.messages) {
                payload.messages.forEach((msg: any) => {
                    const message: ChatMessage = {
                        id: msg.id,
                        role: msg.role,
                        date: msg.createdAt,
                        content: msg.content,
                        feedback: msg.feedback ?? undefined
                    }
                    messages.push(message)
                })
            }
            return messages
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            return []
        })
    return response
}

export const historyGenerate = async (
    options: ConversationRequest,
    abortSignal: AbortSignal,
    convId?: string
): Promise<Response> => {
    let body
    if (convId) {
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages
        })
    } else {
        body = JSON.stringify({
            messages: options.messages
        })
    }
    const response = await fetch('/history/generate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body,
        signal: abortSignal
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            return new Response()
        })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch('/history/update', {
        method: 'POST',
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(async res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const historyDelete = async (convId: string): Promise<Response> => {
    const response = await fetch('/history/delete', {
        method: 'DELETE',
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const historyDeleteAll = async (): Promise<Response> => {
    const response = await fetch('/history/delete_all', {
        method: 'DELETE',
        body: JSON.stringify({}),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const historyClear = async (convId: string): Promise<Response> => {
    const response = await fetch('/history/clear', {
        method: 'POST',
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const historyRename = async (convId: string, title: string): Promise<Response> => {
    const response = await fetch('/history/rename', {
        method: 'POST',
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch('/history/ensure', {
        method: 'GET'
    })
        .then(async res => {
            const respJson = await res.json()
            let formattedResponse
            if (respJson.message) {
                formattedResponse = CosmosDBStatus.Working
            } else {
                if (res.status === 500) {
                    formattedResponse = CosmosDBStatus.NotWorking
                } else if (res.status === 401) {
                    formattedResponse = CosmosDBStatus.InvalidCredentials
                } else if (res.status === 422) {
                    formattedResponse = respJson.error
                } else {
                    formattedResponse = CosmosDBStatus.NotConfigured
                }
            }
            if (!res.ok) {
                return {
                    cosmosDB: false,
                    status: formattedResponse
                }
            } else {
                return {
                    cosmosDB: true,
                    status: formattedResponse
                }
            }
        })
        .catch(err => {
            console.error('There was an issue fetching your data.')
            return {
                cosmosDB: false,
                status: err
            }
        })
    return response
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch('/frontend_settings', {
        method: 'GET'
    })
        .then(res => {
            return res.json()
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            return null
        })

    return response
}
export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch('/history/message_feedback', {
        method: 'POST',
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue logging feedback.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}

export const userHistoryDeleteApi = async (): Promise<Response> => {
    const response = await fetch('/api/delete_user_conversation_history', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(res => {
            return res
        })
        .catch(_err => {
            console.error('There was an issue fetching your data.')
            const errRes: Response = {
                ...new Response(),
                ok: false,
                status: 500
            }
            return errRes
        })
    return response
}
