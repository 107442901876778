import { useRef, useState, useEffect, useContext } from "react";
import { Stack } from "@fluentui/react";
import {
  BroomRegular,
  DismissRegular,
  SquareRegular,
  ShieldLockRegular,
} from "@fluentui/react-icons";
import {
  SpeechRecognizer,
  ResultReason,
} from "microsoft-cognitiveservices-speech-sdk";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { v4 as uuidv4 } from "uuid";

import styles from "./Chat.module.css";
import Azure from "../../assets/skillsiq-logo.png";
import { multiLingualSpeechRecognizer } from "../../util/SpeechToText";

import {
  ChatMessage,
  ConversationRequest,
  callConversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  userHistoryDeleteApi
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import ManageConversation from "../../components/ManageConversation/ManageConversation";
import Tooltip from "../../components/common/Tooltip";
import { getArtifactNamesApi, getConversationHistoryApi } from "../../api";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  webLightTheme,
  FluentProvider
} from "@fluentui/react-components";
// Mapping of messages to colors
const StatusMessageColors: { [key: string]: string } = {
  "Red": "red",
  "Green": "green",
  "Blue": "blue",
};

const getStatusMessageColor = (message: string) => {
  return StatusMessageColors[message] || "black"; // Default to black if no specific color is found
};

const Chat = () => {
  const appStateContext = useContext(AppStateContext);
  const ui = appStateContext?.state.frontendSettings?.ui
  const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
  const lastQuestionRef = useRef<{question:string, date:string}| null>(null);
  const attachRef = useRef<string[]>([]);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean | undefined>()
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusMessageColor, setStatusMessageColor] = useState<string>('black');
  const [showStatus, setShowStatus] = useState(false);
  const [attachments, setAttachments] = useState<File[] | null>(null);
  const [isSendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [visible, setIsHovered] = useState(false);

  const help_url = ui?.help_url || '/';
  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const abortController = new AbortController();
  const abortFuncs = useRef([] as AbortController[]);
  const [conversationId, setConversationId] = useState<string>(uuidv4());
  const [userMessage, setUserMessage] = useState("");
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognizerRef = useRef<SpeechRecognizer | null>(null);
  const getUserInfoList = async () => {
    if (!AUTH_ENABLED) {
      setShowAuthMessage(false)
      return
    }
    try {
      if (window.location.hostname !== '127.0.0.1') {
        setShowAuthMessage(false)
      } else {
        setShowAuthMessage(true)
      }
    }
    catch (error) {
      console.error('Error fetching user ID:', error);
    }
  };

  // Define the data fetching function
  const fetchDocumentsData = async () => {
    try {
      const response = await getArtifactNamesApi(abortController.signal);
      const data = await response.json();

      // Extract evidence and assessment blob names
      const evidenceBlobNames = data.evidence_blob_names || [];
      const assessmentBlobNames = data.assessment_blob_names || [];

      appStateContext?.dispatch({
        type: "UPDATE_EVIDENCES",
        payload: evidenceBlobNames,
      });
      appStateContext?.dispatch({
        type: "UPDATE_ASSESSMENT_FILES",
        payload: assessmentBlobNames,
      });
    } catch (error) {
      console.error("Error fetching artifact names:", error);
    }
  };

  // Conversation history fetching function
  const fetchConversationHistory = async () => {
    try {
      const response = await getConversationHistoryApi(abortController.signal);
      const data = await response.json();
      // code here
      console.log(data)
      // Do nothing if the response is empty
      if (!data || !data.choices) {
        return;
      }

      setAnswers([
        ...data.choices[0].messages,
      ]);
    }
    catch (error) {
      console.error("Error fetching conversation history:", error);
    }
  }

  // Use useEffect to call fetchData when the component mounts
  useEffect(() => {
    fetchConversationHistory();
  }, []);

  const getTime = () => {
      const now: Date = new Date();

      // Get local date components
      const year: number = now.getFullYear();
      const month: number = now.getMonth() + 1; // Months are zero-based
      const day: string = now.getDate().toString();

      // Get local time components and format to 12-hour AM/PM format
      let hours: number = now.getHours();
      const minutes: string = now.getMinutes().toString();
      const seconds: string = now.getSeconds().toString();
      const ampm: string = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; // Convert 0 to 12 (12 AM/PM)

      // Format time string with leading zero for hours
      const formattedTime: string = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${ampm}`;

      // Return final formatted string
      return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')} ${formattedTime}`;
    }

  const makeApiRequest = async (question: string, attachments: File[] | null) => {
    const date = getTime()
    lastQuestionRef.current = {question, date};
    attachRef.current = attachments?.map(attachment => attachment.name) || []
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      role: "user",
      content: recognizedText || question,
      attachments: attachments?.map(attachment => attachment.name)
    };

    let result = {} as ChatResponse;
    try {
      const attachmentNames = attachments ? attachments.map(attachment => attachment.name) : [];
      const lastAnswers = answers.filter((answer) => answer.role !== "tool").slice(-30);
      const request: ConversationRequest = {
        id: conversationId,
        messages: [...lastAnswers, userMessage],
        attachments_exists: (attachments != null && attachments.length > 0),
        files: attachments || [],
        filenames: attachmentNames,
      };
      console.log(request);
      const response = await callConversationApi(
        request,
        abortController.signal
      );

      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder("utf-8").decode(value);
          const objects = text.split("\n");
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              setShowLoadingMessage(false);
              if (result.error) {
                setAnswers([
                  ...answers,
                  userMessage,
                  { role: "error", content: result.error },
                ]);
              } else {
                setAnswers([
                  ...answers,
                  userMessage,
                  ...result.choices[0].messages,
                ]);
              }
              runningText = "";
            } catch { }
          });
        }
        setAnswers([...answers, userMessage, ...result.choices[0].messages]);

        if (attachments != null && attachments.length > 0){
          if (result.file_upload_status) {
            const fileUploadStatus = result.file_upload_status;
            const isSuccess = fileUploadStatus.includes('Attachment(s) uploaded successfully')
            fetchDocumentsData();
            setStatusMessage(fileUploadStatus);
            setStatusMessageColor(isSuccess ? 'Green' : 'Red');
            setShowStatus(true);
          }
        }
      }
    }
    catch (e) {
      if (!abortController.signal.aborted) {
        if (e instanceof Error) {
          console.error(e.message);
        }
        else {
          console.error('An error occurred. Please try again. If the problem persists, please contact the site administrator.');
        }
      }

      setAnswers([...answers, userMessage]);
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      );
    }

    return abortController.abort();
  };
  // Buffer to store recognized text
  let recognizedTextBuffer = "";
  let currentSentence = "";

  const startSpeechRecognition = async () => {
    if (!isRecognizing) {
      setIsRecognizing(true);
      recognizerRef.current = await multiLingualSpeechRecognizer(); // Store the recognizer in the ref

      recognizerRef.current.recognized = (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {
          let recognizedText = e.result.text.trim();
          // Append current sentence to buffer if it's not empty
          if (currentSentence) {
            recognizedTextBuffer += ` ${currentSentence.trim()}`;
            currentSentence = "";
          }
          // Start new sentence
          currentSentence += ` ${recognizedText}`;
          //set text in textarea
          setUserMessage((recognizedTextBuffer + currentSentence).trim());
          setRecognizedText((recognizedTextBuffer + currentSentence).trim());
        }
      };

      recognizerRef.current.startContinuousRecognitionAsync(
        () => {
          setIsRecognizing(true);
          setIsListening(true);
        },
        error => {
          console.error(`Error starting recognition: ${error}`);
        }
      );
    }
  };

  const stopSpeechRecognition = () => {
    if (isRecognizing) {
      if (recognizerRef.current) {
        recognizerRef.current.stopContinuousRecognitionAsync(() => {
          recognizerRef.current?.close();
        });
      }
      setIsRecognizing(false);
      setRecognizedText("");
      setSendButtonDisabled(false);
      setIsListening(false);
    }
  };

  const onMicrophoneClick = async () => {
    if (!isRecognizing) {
      setSendButtonDisabled(true);
      await startSpeechRecognition();
    } else {
      stopSpeechRecognition();
      setRecognizedText(userMessage);
    }
  };

  const handleDelete = async() => {
    try {
      await userHistoryDeleteApi();
      clearChat();
    }
    catch (error) {
    console.error('Error deleting user history:', error);
    }
  }

  const clearChat = () => {
    lastQuestionRef.current = null;
    attachRef.current = [];
    setActiveCitation(undefined);
    setAnswers([]);
    setConversationId(uuidv4());
    setAttachments(null);
    setShowStatus(false);
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }),
    [showLoadingMessage]
  );

  useEffect(() => {
    if (AUTH_ENABLED !== undefined) getUserInfoList()
  }, [AUTH_ENABLED])

  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation.content,
      citation.id,
      citation.title ?? "",
      citation.filepath ?? "",
      "",
      "",
    ]);
    setIsCitationPanelOpen(true);
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message.role === "tool") {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const parseContentFromMessage = (message: ChatMessage) => {
    try {
      const toolMessage = JSON.parse(message.content) as ToolMessageContent;
      return toolMessage.intent;
    } catch {
      return message.content;
    }
  };

  const handleCloseStatusMessage = () => {
    setStatusMessage('');
    setShowStatus(false);
  };

  return (
    <div className={styles.container}>
      {showAuthMessage ? (
        <Stack className={styles.chatEmptyState}>
          <ShieldLockRegular
            className={styles.chatIcon}
            style={{ color: 'darkorange', height: '200px', width: '200px' }}
          />
          <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
          <h2 className={styles.chatEmptyStateSubtitle}>
            This app does not have authentication configured. Please add an identity provider by finding your app in the{' '}
            <a href="https://portal.azure.com/" target="_blank">
              Azure Portal
            </a>
            and following{' '}
            <a
              href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
              target="_blank">
              these instructions
            </a>
            .
          </h2>
          <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: '20px' }}>
            <strong>Authentication configuration takes a few minutes to apply. </strong>
          </h2>
          <h2 className={styles.chatEmptyStateSubtitle} style={{ fontSize: '20px' }}>
            <strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong>
          </h2>
        </Stack>
      ) : (
      <Stack horizontal className={styles.chatRoot}>
        <div className={`${styles.chatContainer} ${styles.MobileChatContainer}`}>
          {!lastQuestionRef.current && answers.length === 0 ? (
            <Stack className={styles.chatEmptyState}>
              <img src={Azure} className={styles.chatIcon} aria-hidden="true" alt="SkillsAware logo"/>
              <h1 className={styles.chatEmptyStateTitle}>{ui?.chat_title}</h1>
              <h2 className={styles.chatEmptyStateSubtitle}>
                {ui?.chat_description}
              </h2>
                <a href={ui?.help_url} className={styles.chatEmptyStateUrl} target="_blank" rel="noopener noreferrer"><em>{ui?.chat_subtext}</em></a>
            </Stack>
          ) : (
            <div
              className={styles.chatMessageStream}
              style={{ marginBottom: isLoading ? "40px" : "0px" }}
            >
              {answers.map((answer, index) => (
                <>
                  {answer.role === "user" ? (
                    <div className={styles.chatMessageUser}>
                      <Tooltip content={answer.date} side="bottom" style={{fontSize:"11px"}}>
                        <div className={styles.chatMessageUserMessage}>
                          {parseContentFromMessage(answer)}
                          <div>
                            {answer.attachments?.map((attachment, index) => <div key={index}>📄 {attachment}</div>)}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ) : answer.role === "assistant" || answer.role === "error" ? (
                    <div className={styles.chatMessageGpt}>
                      <Answer
                        answer={{
                          answer:
                            answer.role === "assistant"
                              ? parseContentFromMessage(answer)
                              : "Sorry, an error occurred. Try refreshing the conversation or waiting a few minutes. If the issue persists, contact your system administrator. Error: " +
                              answer.content,
                          citations:
                            (answer.role === "assistant") && answers[index - 1]
                              ? parseCitationFromMessage(answers[index - 1])
                              : [],
                          date: answer.date
                        }}
                        onCitationClicked={(c) => onShowCitation(c)}
                        index={index}
                      />
                    </div>
                  ) : null}
                </>
              ))}
              {showLoadingMessage && (
                <>
                    <div className={styles.chatMessageUser}>
                      <Tooltip content={lastQuestionRef.current?.date} side="bottom" style={{fontSize:"11px"}}>
                          <div className={styles.chatMessageUserMessage}>
                            {lastQuestionRef.current?.question}
                            <div>
                                {attachRef.current.map((attachment, index) => <div key={index}>📄 {attachment}</div>)}
                            </div>
                          </div>
                      </Tooltip>
                    </div>
                  <div className={styles.chatMessageGpt}>
                    <Answer
                      answer={{
                        answer: "Generating answer...",
                        citations: [],
                      }}
                      onCitationClicked={() => null}
                      index={0}
                    />
                  </div>
                </>
              )}
              <div ref={chatMessageStreamEnd} />
            </div>
          )}
          <div>
            {isRecognizing && !isListening && <p>Please wait...</p>}{" "}
            {isListening && <p>Listening...</p>}{" "}
          </div>
          <div style={{ padding: "5px", position:'relative', marginBottom: '30px'}}>
            {statusMessage && (<Stack
                  className="warning-message" style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', color: getStatusMessageColor(statusMessageColor), padding: '5px', alignItems: 'center'}}>
                  <span>{statusMessage}<button style={{ marginBottom: "10px", fontSize: "16px", cursor: "pointer", background: "none", border: "1px solid black", color: getStatusMessageColor(statusMessageColor)}}
                  onClick={handleCloseStatusMessage}>&times;</button></span>
                  </Stack>
            )}
          </div>
          <Stack horizontal className={styles.chatInput} style={{display: 'flex'}}>
            {isLoading && (
              <Stack
                horizontal
                className={styles.stopGeneratingContainer}
                role="button"
                aria-label="Stop generating"
                tabIndex={0}
                onClick={stopGenerating}
                onKeyDown={(e) =>
                  e.key === "Enter" || e.key === " " ? stopGenerating() : null
                }
                style={{ display: "flex", alignItems: "center", cursor: "pointer", whiteSpace: "nowrap", marginTop: '25px'}}
              >
                <SquareRegular
                  className={styles.stopGeneratingIcon}
                  aria-hidden="true"
                />
                <span className={styles.stopGeneratingText} aria-hidden="true">
                  Stop generating
                </span>
              </Stack>
            )}
            <FluentProvider theme={webLightTheme}>
              <Dialog>
                <DialogTrigger disableButtonEnhancement>
                  <Button style={{minWidth: 0, padding: 0, border: "none"}} disabled={isLoading || answers.length === 0}>
                    <Tooltip content = "Clicking this button will clear the current chat and start a new one. However, any files shared or assessments completed will still be available.">
                      <BroomRegular
                        className={`${styles.clearChatBroom} ${styles.mobileclearChatBroom}`}
                        style={{
                          background:
                            isLoading || answers.length === 0
                              ? "#BDBDBD"
                              : "radial-gradient(109.81% 107.82% at 100.1% 90.19%, #0F6CBD 33.63%, #2D87C3 70.31%, #8DDDD8 100%)",
                          cursor: isLoading || answers.length === 0 ? "" : "pointer",
                        }}
                        onKeyDown={(e) =>
                          e.key === "Enter" || e.key === " " ? clearChat() : null
                        }
                        aria-label="Clear session"
                        role="button"
                        tabIndex={0}
                      />
                    </Tooltip>
                  </Button>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>Are you sure you want to clear all conversation history?</DialogTitle>
                    <DialogContent style={{fontSize:"14px", fontFamily:"Arial"}}>
                      Clicking this button will clear all previous chat history. However, previous evidence artifacts and assessment files will still be available.
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" onClick={handleDelete}>Clear History</Button>
                      </DialogTrigger>
                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Cancel</Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </FluentProvider>

            <QuestionInput
              clearOnSend
              placeholder="Type a new question..."
              disabled={isLoading}
              onSend={(question) => makeApiRequest(question, attachments)}
              recognizedText={recognizedText}
              isSendButtonDisabled={isSendButtonDisabled}
              onMicrophoneClick={onMicrophoneClick}
              onStopClick={stopSpeechRecognition}
              isListening={isListening}
              isRecognizing={isRecognizing}
              setRecognizedText={setRecognizedText}
              attachments={attachments}
              onAttachmentChange={setAttachments}
            />
          </Stack>
        </div>
        {answers.length > 0 && isCitationPanelOpen && activeCitation && (
          <Stack.Item className={`${styles.citationPanel} ${styles.mobileStyles}`}>
            <Stack
              horizontal
              className={styles.citationPanelHeaderContainer}
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <span className={styles.citationPanelHeader}>Citations</span>
              <DismissRegular
                className={styles.citationPanelDismiss}
                onClick={() => setIsCitationPanelOpen(false)}
              />
            </Stack>
            <div className={`${styles.citationPanelContent} ${styles.mobileCitationPanelContent}`}>
              <div
              dangerouslySetInnerHTML={{
                __html: activeCitation[0]?.replace(
                  /\[Source link\]\((http[^\)]+)\)/g,
                  '<a href="$1" target="_blank" rel="noopener noreferrer">Source link</a>'
                ) || ""
              }}
              />
            </div>
          </Stack.Item>
        )}
        <ManageConversation />
      </Stack>
    )}
    </div>
  );
};

export default Chat;
