import React, { ReactNode } from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import styles from "./Tooltip.module.css";
// Define the props interface for the Tooltip component
interface TooltipProps {
  content: ReactNode; // The content to display in the tooltip
  children: ReactNode; // The child element that will trigger the tooltip
  className?: string; // Optional additional class names
  offset?: number; // Optional offset for the tooltip position
  side?: "top" | "right" | "bottom" | "left"; // The position of the tooltip
  style?: React.CSSProperties
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  className = "",
  offset = 5,
  side = "top",
  style = {}
}) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={styles.tooltip}
            sideOffset={offset}
            side={side}
            style={style}
          >
            {content}
            <RadixTooltip.Arrow style={{fill: "white"}} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
