import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import Azure from "../../assets/skillsiq-logo.png";
import {
  CopyRegular,
  ShareRegular,
  MoreCircle32Filled,
  MoreCircle32Regular,
} from "@fluentui/react-icons";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useEffect, useState, useContext } from "react";
import { deleteArtifactNamesApi } from "../../api";
import { AppStateContext } from "../../state/AppProvider";
const Layout = () => {
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<string>("Copy URL");
  const appStateContext = useContext(AppStateContext);
  const ui = appStateContext?.state.frontendSettings?.ui;
  const abortController = new AbortController();
  // const handleShareClick = () => {
  //     setIsSharePanelOpen(true);
  // };

  // const handleSharePanelDismiss = () => {
  //     setIsSharePanelOpen(false);
  //     setCopyClicked(false);
  //     setCopyText("Copy URL");
  // };

  // const handleCopyClick = () => {
  //     navigator.clipboard.writeText(window.location.href);
  //     setCopyClicked(true);
  // };

  // useEffect(() => {
  //     if (copyClicked) {
  //         setCopyText("Copied URL");
  //     }
  // }, [copyClicked]);

  // Deleting unction
  const deleteData = async () => {
    try {
      const body = {
        evidence: appStateContext?.state.selectedEvidences || [],
        assessment: appStateContext?.state.selectedAssessmentFiles || [],
      };
      const response = await deleteArtifactNamesApi(
        body,
        abortController.signal
      );
      const data = await response.json();

      // Delete Names
      const updatedEvidences = appStateContext?.state.evidences.filter(
        (evidence) =>
          !appStateContext?.state.selectedEvidences.find(
            (selectedEvidence) => evidence.title === selectedEvidence
          )
      );
      const updatedAssessmentFiles =
        appStateContext?.state.assessmentFiles.filter(
          (assessment) =>
            !appStateContext?.state.selectedAssessmentFiles.find(
              (selectedAssessmentFile) =>
                assessment.title === selectedAssessmentFile
            )
        );
      appStateContext?.dispatch({
        type: "UPDATE_EVIDENCES",
        payload: updatedEvidences || [],
      });
      appStateContext?.dispatch({
        type: "UPDATE_ASSESSMENT_FILES",
        payload: updatedAssessmentFiles || [],
      });
      appStateContext?.dispatch({
        type: "UPDATE_SELECTED_EVIDENCES",
        payload: [],
      })
      appStateContext?.dispatch({
        type: "UPDATE_SELECTED_ASSESSMENT_FILES",
        payload: [],
      });
    } catch (error) {
      console.error("Error fetching artifact names:", error);
    }
  };

  const handleClick = () => {
    appStateContext?.dispatch({ type: "TOGGLE_MANAGE_PANEL" });
  };

  const handleDelete = () => {
    deleteData();
  };
  const isAnyItemSelected = !appStateContext
    ? false
    : appStateContext.state.selectedEvidences.length > 0 ||
      appStateContext.state.selectedAssessmentFiles.length > 0;
  return (
    <div className={styles.layout}>
      <header className={styles.header} role={"banner"}>
        <div className={styles.headerContainer}>
          <Stack horizontal verticalAlign="center">
            <img
              src={Azure}
              className={styles.headerIcon}
              aria-hidden="true"
              alt="SkillsAware logo"
            />
            <Link to={ui?.title_url || "/"} className={styles.headerTitleContainer} target="_blank" rel="noopener noreferrer">
              <h3 className={styles.headerTitle}>{ui?.title}</h3>
            </Link>
          </Stack>
        </div>
        <div className={styles.buttons}>
          {isAnyItemSelected && appStateContext?.state.isManagePanel && (
            <div className={styles.btnContainer}>
              <button className={styles.button} onClick={handleDelete}>
                Delete
              </button>
            </div>
          )}
          {appStateContext?.state.isManagePanel ? (
            <MoreCircle32Regular
              className={styles.moreButton}
              onClick={handleClick}
            />
          ) : (
            <MoreCircle32Filled
              className={styles.moreButton}
              onClick={handleClick}
            />
          )}
        </div>
      </header>
      <Outlet />
      {/* <Dialog
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{

                    main: [{
                        selectors: {
                          ['@media (min-width: 480px)']: {
                            maxWidth: '600px',
                            background: "#FFFFFF",
                            boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                            borderRadius: "8px",
                            maxHeight: '200px',
                            minHeight: '100px',
                          }
                        }
                      }]
                }}
                dialogContentProps={{
                    title: "Share the web app",
                    showCloseButton: true
                }}
            >
                <Stack horizontal verticalAlign="center" style={{gap: "8px"}}>
                    <TextField className={styles.urlTextBox} defaultValue={window.location.href} readOnly/>
                    <div
                        className={styles.copyButtonContainer}
                        role="button"
                        tabIndex={0}
                        aria-label="Copy"
                        onClick={handleCopyClick}
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                    >
                        <CopyRegular className={styles.copyButton} />
                        <span className={styles.copyButtonText}>{copyText}</span>
                    </div>
                </Stack>
            </Dialog> */}
    </div>
  );
};

export default Layout;
