import { AskResponse, Citation, AzureSqlServerCodeExecResult } from "../../api";
import { cloneDeep } from "lodash-es";


export type ParsedAnswer = {
    citations: Citation[];
    markdownFormatText: string;
    plotly_data?: AzureSqlServerCodeExecResult | null
};

let filteredCitations = [] as Citation[];

// Define a function to check if a citation with the same Chunk_Id already exists in filteredCitations
const isDuplicate = (citation: Citation,citationIndex:string) => {
    return filteredCitations.some((c) => c.chunk_id === citation.chunk_id) && !filteredCitations.find((c) => c.id === citationIndex) ;
};

// Below is included in: https://github.com/microsoft/sample-app-aoai-chatGPT/tree/main/frontend/src/components/Answer/AnswerParser.tsx
export const enumerateCitations = (citations: Citation[]) => {
    const filepathMap = new Map()
    for (const citation of citations) {
      const { filepath } = citation
      let part_i = 1
      if (filepathMap.has(filepath)) {
        part_i = filepathMap.get(filepath) + 1
      }
      filepathMap.set(filepath, part_i)
      citation.part_index = part_i
    }
    return citations
  }

export function parseAnswer(answer: AskResponse): ParsedAnswer {
    let answerText = answer.answer;
    const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

    const lengthDocN = "[doc".length;

    filteredCitations = [] as Citation[];
    let citationReindex = 0;
    citationLinks?.forEach(link => {
        // Replacing the links/citations with number
        let citationIndex = link.slice(lengthDocN, link.length - 1);
        let citation = cloneDeep(answer.citations[Number(citationIndex) - 1]) as Citation;
        if (!isDuplicate(citation, citationIndex) && citation !== undefined) {
          answerText = answerText.replaceAll(link, ` ^${++citationReindex}^ `);
          citation.id = citationIndex; // original doc index to de-dupe
          citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
          filteredCitations.push(citation);
        }else{
            // Replacing duplicate citation with original index
            let matchingCitation = filteredCitations.find((ct) => citation.chunk_id == ct.chunk_id);
            if (matchingCitation) {
                answerText= answerText.replaceAll(link, ` ^${matchingCitation.reindex_id}^ `)
            }
        }
    })

    // Below is included in: https://github.com/microsoft/sample-app-aoai-chatGPT/tree/main/frontend/src/components/Answer/AnswerParser.tsx
    // filteredCitations = enumerateCitations(filteredCitations)

    return {
        citations: filteredCitations,
        markdownFormatText: answerText
    };
}
