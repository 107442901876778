import React, { useState, useEffect, useRef } from "react";
import { ITextField, Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import MicrophoneIcon from "../../assets/mic-outline.svg";
import styles from "./QuestionInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../common/Tooltip";
interface Props {
  onSend: (question: string, attachments: File[]) => void;
  onMicrophoneClick: () => void;
  onStopClick: () => void;
  disabled: boolean;
  isSendButtonDisabled:boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  recognizedText: string;
  isListening: boolean;
  isRecognizing: boolean;
  setRecognizedText: (text: string) => void;
  attachments: File[] | null;
  onAttachmentChange: (attachments: File[] | null) => void;
}

export const QuestionInput = ({
  onSend,
  onMicrophoneClick,
  onStopClick,
  disabled,
  isSendButtonDisabled,
  placeholder,
  clearOnSend,
  recognizedText,
  isListening,
  isRecognizing,
  setRecognizedText,
  attachments,
  onAttachmentChange,
}: Props) => {
  const [question, setQuestion] = useState<string>("");
  const [liveRecognizedText, setLiveRecognizedText] = useState<string>("");
  const [microphoneIconActive, setMicrophoneIconActive] =
    useState<boolean>(false);
  const [isTextAreaDisabled, setIsTextAreaDisabled] = useState(false);
  //const [attachments, setAttachments] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)


  useEffect(() => {
    if (isRecognizing) {
      setLiveRecognizedText(recognizedText);
      setIsTextAreaDisabled(true)
      setMicrophoneIconActive(true); // Set microphone icon to active (blue)
    } else {
      setIsTextAreaDisabled(false)
      setMicrophoneIconActive(false); // Set microphone icon to inactive
    }
  }, [recognizedText, isRecognizing]);

  // Function to adjust the height dynamically
  const adjustTextAreaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto to shrink if needed
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scrollHeight
    }
  };

  const sendQuestion = async() => {
    try {
      let textToSend = "";

      if (disabled || (!question.trim() && (attachments === null || attachments.length === 0))) {
        return;
      }

      textToSend = question || liveRecognizedText;
      if (!textToSend.trim() && !(attachments === null || attachments.length === 0)) {
        textToSend= "Attachment(s):";
      }

      onSend(textToSend, attachments as File[]);

      if (clearOnSend) {
        setQuestion("");
        setLiveRecognizedText("");
        setRecognizedText(""); // Clear recognizedText
        onAttachmentChange(null); // Clear attachments
      }
    }
    catch (error) {
      setQuestion("");
      setLiveRecognizedText("");
      setRecognizedText(""); // Clear recognizedText
      onAttachmentChange(null); // Clear attachments
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  }

  const onQuestionChange = (newValue: string) => {
    setQuestion(newValue);
    setLiveRecognizedText(newValue);
    setRecognizedText(newValue);
    adjustTextAreaHeight(); // Adjust the textarea height as the content changes
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newAttachments = [...(attachments || []), ...event.target.files];
      onAttachmentChange(newAttachments);
      if(inputRef.current)
        {inputRef.current.value = "";
          inputRef.current.style.height = "auto"; // Reset height to auto to shrink if needed
          inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set heig
        }
    }
  };
  const removeAttachment = (index: number) => {
    const newAttachments = [...(attachments || [])];
    newAttachments.splice(index, 1)
    onAttachmentChange(newAttachments);
  };

  const sendQuestionDisabled = disabled || !question.trim();

  useEffect(() => {
    document.getElementById("question-field")?.focus();
  }, []);
  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <div className={styles.questionInputGroup}>
        {/* Text Input Field */}
        <textarea
          id="question-field"
          disabled={isTextAreaDisabled}
          className={`${styles.questionInputTextArea} ${styles.scroll}`}
          placeholder={placeholder}
          value={question || liveRecognizedText}
          onChange={(e) => onQuestionChange(e.target.value)}
          onKeyDown={onEnterPress}
          ref={textareaRef} // Attach the ref for dynamic resizing
        />
        {/* Display Attachments Name */}

        {attachments && attachments.length > 0 && (
            <div className={`${styles.attachmentPreviewContainer} ${styles.scroll}`}>
                {attachments.map((attachment, index) => (
                  <div key={index} className={styles.attachmentFileIcon}>
                    <div>
                      <span className={styles.fileIcon}>📄</span>
                      <span className={styles.fileName}>{attachment.name}</span>
                    </div>
                    <button
                      onClick={() => removeAttachment(index)}
                      style={{ fontSize: "25px", color: 'red', cursor: 'pointer', background: 'none', border: 'none'}}
                      >&times;
                    </button>
                  </div>
                ))}
            </div>
          )}
      </div>

      <div className={styles.microphoneAndSendContainer}>
          {/* Microphone Icon */}
          <Tooltip content = "Click this button to use voice instead of typing your message">
            <div
              className={styles.questionInputMicrophone}
              onClick={isListening ? onStopClick : onMicrophoneClick}
              onKeyDown={(e) =>
                e.key === "Enter" || e.key === " "
                  ? isListening
                    ? onStopClick()
                    : onMicrophoneClick()
                  : null
              }
              role="button"
              tabIndex={0}
              aria-label="Microphone button"
            >
              {microphoneIconActive ? (
                <FontAwesomeIcon
                  icon={faMicrophone}
                  className={styles.microphoneIconActive}
                  style={{ color: "blue" }}
                />
              ) : (
                <img
                  src={MicrophoneIcon}
                  className={styles.microphoneIcon}
                  alt="Microphone"
                />
              )}
            </div>
          </Tooltip>
          {/* Attachment Icon */}
          <Tooltip content="Click this button to upload attachments">
            <div className={styles.questionInputAttachment}>
              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
                ref={inputRef}
              />
              <label htmlFor="fileInput">
                <FontAwesomeIcon icon={faPaperclip} className={styles.attachmentIcon} />
              </label>
            </div>
          </Tooltip>

          {/* Send Button */}
          <Tooltip content="Press the ENTER key or click this button to send your message">
            {isSendButtonDisabled?( <SendRegular className={styles.SendButtonDisabled} />):(
              <div
              role="button"
              tabIndex={0}
              aria-label="Ask question button"
              onClick={sendQuestion}
              onKeyDown={(e) =>
                e.key === "Enter" || e.key === " " ? sendQuestion() : null
              }
              className={styles.questionInputSendButtonContainer}
              >
              {disabled? (
                <SendRegular className={styles.questionInputSendButtonDisabled} />
              ) : (
                <img
                  src={Send}
                  className={styles.questionInputSendButton}
                  alt="Send"
                />
              )}
              </div>
            )}
          </Tooltip>
      </div>
    </Stack>
  );
};
