import Plotly from 'react-plotly.js'

export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    plotly_data?: AzureSqlServerCodeExecResult | null
    message_id?: string
    feedback?: Feedback
    exec_results?: ExecResults[]
    date?: string
};

export type Citation = {
    part_index?: number
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
}

export type AzureSqlServerCodeExecResult = {
    data: Plotly.Data[]
    layout: Partial<Plotly.Layout>
}

export type AzureSqlServerExecResult = {
    intent: string
    search_query: string | null
    search_result: string | null
    code_generated: string | null
    code_exec_result?: AzureSqlServerCodeExecResult | undefined
}

export type AzureSqlServerExecResults = {
    all_exec_results: AzureSqlServerExecResult[]
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    role: string;
    content: string;
    end_turn?: boolean;
    id?: string;
    date?: string
    feedback?: Feedback
    context?: string
    attachments?:string[]
};

export type ExecResults = {
    intent: string
    search_query: string | null
    search_result: string | null
    code_generated: string | null
}

export type Conversation = {
    id: string
    title: string
    messages: ChatMessage[]
    date: string
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    file_upload_status: string;
    // history_metadata: {
    //     conversation_id: string
    //     title: string
    //     date: string
    // }
    error: string;
    // error?: any
}

// export type ConversationRequest = {
//     id?: string;
//     messages: ChatMessage[];
//     attachment_urls?: string[];
//     attachments_exists: boolean;
//     user_id: string;
// };

export type LtiLogin = {
    target_link_uri?: string
}

export type LaunchRequest = {
    page_title: string
    launch_id: string
    user_id: string
    user_email: string
    user_given_name: string
    user_last_name: string
    is_deep_link_launch?: boolean
    launch_data?: any | any[]
    curr_diff?: string
}

export type UserInfo = {
    access_token: string
    expires_on: string
    id_token: string
    provider_name: string
    user_claims: any[]
    user_id: string
}

export type ConversationRequest = {
    id: string;
    messages: ChatMessage[];
    attachments_exists: boolean;
    files?: File[];
    filenames?: string[];
}

export enum CosmosDBStatus {
    NotConfigured = 'CosmosDB is not configured',
    NotWorking = 'CosmosDB is not working',
    InvalidCredentials = 'CosmosDB has invalid credentials',
    InvalidDatabase = 'Invalid CosmosDB database name',
    InvalidContainer = 'Invalid CosmosDB container name',
    Working = 'CosmosDB is configured and working'
}

export type CosmosDBHealth = {
    cosmosDB: boolean
    status: string
}

export enum ChatHistoryLoadingState {
    Loading = 'loading',
    Success = 'success',
    Fail = 'fail',
    NotStarted = 'notStarted'
}

export type ErrorMessage = {
    title: string
    subtitle: string
}

export type UI = {
    title: string
    title_url: string
    chat_title: string
    chat_description: string
    chat_subtext: string
    logo?: string
    chat_logo?: string
    show_share_button?: boolean
    help_url?: string
}

export type FrontendSettings = {
    auth_enabled?: string | null
    feedback_enabled?: string | null
    ui?: UI
    sanitize_answer?: boolean
}

export enum Feedback {
    Neutral = 'neutral',
    Positive = 'positive',
    Negative = 'negative',
    MissingCitation = 'missing_citation',
    WrongCitation = 'wrong_citation',
    OutOfScope = 'out_of_scope',
    InaccurateOrIrrelevant = 'inaccurate_or_irrelevant',
    OtherUnhelpful = 'other_unhelpful',
    HateSpeech = 'hate_speech',
    Violent = 'violent',
    Sexual = 'sexual',
    Manipulative = 'manipulative',
    OtherHarmful = 'other_harmlful'
}

export interface DeleteNamesRequest {
    evidence: string [],
    assessment: string []
}
